@import '../../../css/mixins.scss';

.card {
	margin: 30px 0 0 0;
	border-radius: 10px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin: 15px 15px 0 0;

	@include xl {
		margin: 0;
		margin-top: 16px;
		box-shadow: none;
		border-radius: 5px;
		padding: 16px;
		position: relative;
	}
}

.craneDetailsTable {
	margin-top: 30px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);

	@include xl {
		display: flex;
		flex-direction: column;
	}
}
.cardTitle {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: right;
	color: #303030;

	@include xl {
		color: #7AB7FF;
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
	}
}

.modalTitle {
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 30px;
}

.editDetailsModal {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
}

.detailsParagraph,
.detailsForm {
	min-height: 140px;
	font-size: 15px;
}

.saveButton {
	background-color: #3468ff;
	border-radius: 4px;
	color: white;
	font-weight: 500;
	width: 100px;
	height: 30px;
	text-align: center;
	margin-top: 15px;
	font-size: 14px;
}

.editDetailsModal input[type="text"],
.editDetailsModal input[type="tel"],
.editDetailsModal input[type="date"],
.editDetailsModal select {
	height: 32px;
	width: 150px;
	border: 1px solid #a8abb7;
	box-sizing: border-box;
	border-radius: 5px;
	margin-right: 10px;
}

.editDetailsModal input[type="number"] {
	height: 32px;
	width: 50px;
	border: 1px solid #a8abb7;
	box-sizing: border-box;
	border-radius: 5px;
	margin-right: 5px;
}

.exitButton {
	margin: 5px 10px 0 5px;
	width: max-content;
	align-self: flex-end;
	font-weight: 500;
	font-size: 14px;
	color: #a8abb7;
}

.modalBody {
	display: grid;
	align-items: center;
	justify-items: center;
}

.editDetailsForm {
	display: grid;
	grid-template-rows: repeat(15, 1fr);
	gap: 5px;
	align-content: center;
	justify-items: center;
}

.editDetailsForm > div {
	display: inline-flex;
	width: 300px;
	align-self: start;
}

.detailsKey {
	float: right;
	align-self: center;
}

.icons {
	margin-left: 10px;
	float: left;

	@include xl {
		margin-left: 0;
	}
}

.icons > img {
	width: 16px;
	height: 16px;
	float: center;
}

.paragraphTitle {
	font-weight: 500;
	font-size: 14px;
	color: #7ab7ff;

	@include xl {
		font-family: Rubik;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		color: #A8ABB7;
	}
}

.details {
	display: inline-flex;
}

.tableDetailsKey {
	width: 90px;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #242530;
	margin-left: 10px;

	@include xl {
		color: #242530;
		font-family: Rubik;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
	}
}

.tableDetailsValue {
	width: max-content;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: #242530;

	@include xl {
		color: #242530;
		font-family: Rubik;
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 19px;
	}
}

.craneDataParagraph,
.blackboxParagraph,
.testsParagraph {
	display: flex;
	flex-direction: column;
	gap: 15px;

	@include xl {
		border-bottom: 1px solid #DBDCE8;
		padding-bottom: 20px;
		margin-bottom: 32px;
	}
}

.testsParagraph {
	@include xl {
		margin-bottom: 0;
		padding-bottom: 0;
		border-bottom: 0;
	}
}
