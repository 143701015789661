@import '../../../css/mixins.scss';

.modal {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	direction: rtl;

	@include xl {
		z-index: 10000;

		div:first-child {
			margin: 0;
			width: 100%;
			height: 100%;
			max-width: none;
		}

		div {
			border-radius: 0 !important;
		}
	}
}

.modalHeader {
	@include xl {
		height: 5% !important;
		padding: 10px 0 0 0;

		button {
			margin: 0 auto 0 0 !important;
		}

		span:first-child {
			font-weight: normal;
			font-size: 1.6em;
			position: relative;
			top: -18px;
		}
	}
}

.modalBody {
	@include xl {
		overflow-y: scroll;
	}
}

.title {
	text-align: center;
	font-weight: 600;
	font-size: 16px;

	@include xl {
		height: 10% !important;
	}
}
.detailsParagraph,
.detailsForm {
	min-height: 140px;
	font-size: 15px;
	margin: 0 15px 15px 0;
	text-align: right;
}

.detailsKey,
label {
	display: inline-block;
	width: 130px;
	font-weight: 500;
	font-size: 14px;
	color: #7ab7ff;
}

.detailsValue {
	margin-left: 7px;
}
