@import '../../../css/mixins.scss';

.notificationCard {
	display: grid;
	grid-template-columns: 55% 45%;
	border-radius: 5px;
	background-color: #f9f9f9;
	border: 1px solid #d1d1d1;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	width: 224px;
	height: 112px;
	column-gap: 2px;
	margin: 15px 30px 15px 0;
	/* column-gap: 10px; */

	@include xl {
		width: 100%;
		margin: 16px 0 0 0;
		background-color: #fff;
		border: 1px solid #dbdce8;
		box-shadow: none;
		padding: 12px 16px;
		display: flex;
		height: auto;
	}
}

.rightSectionControl {
	display: grid;
	grid-template-rows: 40% 30% 30%;
	max-height: 112px;
	padding: 5px 5px 5px 0;
	border-left: 1px solid lightgray;

	@include xl {
		flex: 1;
		display: flex;
		flex-direction: column;
		max-height: unset;
		padding: 0;

		.crane {
			font-family: Rubik;
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 24px;
			color: #101010;
			margin-bottom: 28px;
		}

		.sensor {
			> span {
				font-family: Rubik;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 14px;
				color: #101010;
			}
		}
	}
}
/* .rightSectionControl .crane {
	font-size: 18px;
} */

.leftSectionControl {
	display: grid;
	grid-auto-rows: 20px;
	grid-template-rows: 33% 33% 33%;
	max-height: 112px;

	@include xl {
		width: 104px;
		max-height: unset;
		display: flex;
		flex-direction: column;
	}
}

.icons {
	width: 20px;
	height: 20px;
	margin-left: 5px;
}

.sensor span {
	color: rgb(73, 73, 73);
	/* display: inline-block; */
	/* padding-right: 5px; */
	font-size: 12px;
	/* max-width: 75px; */
}

.notificationSwitchButton {
	display: flex;
	justify-content: center;
	align-items: center;

	@include xl {
		margin-bottom: 12px;
		height: 24px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.svgActiveStroke {
	stroke: #101010;
}
.svgActiveFill {
	fill: #101010;
}
.svgInactiveStroke {
	stroke: #dddddd;
}
.svgInactiveFill {
	fill: #dddddd;
}
