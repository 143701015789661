@import './../../../css/mixins.scss';

.loader,
.loader:before,
.loader:after {
	border-radius: 50%;
}
.loader {
	color: #88d7ff;
	font-size: 11px;
	text-indent: -99999em;
	margin: 55px auto;
	position: relative;
	width: 10em;
	height: 10em;
	box-shadow: inset 0 0 0 1em;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);

	@include xl {
		margin: 0;
		position: fixed;
		top: calc(50% - 50px);
		left: calc(50% - 50px);
	}
}

.loaderContainer {
	@include xl {
		top: 0;
		z-index: 3;
		height: 100%;
		position: fixed;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.15);
		pointer-events: none;
	}
}

.loader:before,
.loader:after {
	position: absolute;
	content: "";
}
.loader:before {
	width: 5.2em;
	height: 10.2em;
	background-color: #f8f9fa;
	border-radius: 10.2em 0 0 10.2em;
	top: -0.1em;
	left: -0.1em;
	-webkit-transform-origin: 5.1em 5.1em;
	transform-origin: 5.1em 5.1em;
	-webkit-animation: load2 2s infinite ease 1.5s;
	animation: load2 2s infinite ease 1.5s;
}
.loader:after {
	width: 5.2em;
	height: 10.2em;
	background-color: #f8f9fa;
	border-radius: 0 10.2em 10.2em 0;
	top: -0.1em;
	left: 4.9em;
	-webkit-transform-origin: 0.1em 5.1em;
	transform-origin: 0.1em 5.1em;
	-webkit-animation: load2 2s infinite ease;
	animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load2 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
