@import '../../../css/mixins.scss';

.wrapperCard {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin-top: 30px;

	@include xl {
		margin-top: 16px;
		margin: 16px 16px 0 16px;
		padding: 16px;
		box-shadow: none;
	}
}

.companyName {
	margin: 2%;
	font-weight: 500;
	font-size: 16px;
	color: black;

	@include xl {
		font-family: Rubik;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #101010;
		margin: 0;
	}
}
