.modal {
	border-color: #f0f0f0;
}
.modalHeader {
	background-color: #ffffff;
}

.modalBody {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	background-color: #ffffff;
	align-items: center;
}

.errorDescription {
	overflow-wrap: break-word;
	max-width: 95%;
	text-align: left;
	font-weight: normal;
	max-height: 25rem;
	overflow-y: scroll;
}

.errorDescriptionButton {
	width: max-content;
}
