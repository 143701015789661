@import '../../../css/mixins.scss';

.card {
	margin: 30px 0 0 0;
	border-radius: 10px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin: 15px 15px 0 0;

	@include xl {
		box-shadow: none;
		padding: 0;
		margin: 46px 16px 0 16px;
		position: relative;
		border-radius: 5px;
	}
}

.table {
	margin: 30px;
}

.Header {
	.mobileAddBtn {
		display: none;
	}

	@include xl {
		position: absolute;
		top: -30px;
		left: 0;

		.mobileAddBtn {
			display: flex;

			svg {
				width: 16px;
				height: 16px;
				margin-left: 7px;
			}

			span {
				color: #242530;
				font-family: Rubik;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 17px;
			}
		}
	}
}

.cardHeader {
	display: flex;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: right;
	color: #303030;
}

.cardTitle {
	float: right;

	@include xl {
		display: none;
	}
}

.title {
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

thead th {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #7ab7ff;
}

tbody td {
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: black;
}

.addContactButton {
	display: inline-flex;
	float: left;

	@include xl {
		> div {
			display: none;
		}
	}
}

.button {
	float: left;

	@include xl {
		position: absolute;
		background: transparent !important;
		padding: 0 5px !important;
		opacity: 0.7;

		&.delete {
			left: 11px;
			top: 68px;
		}

		&.edit {
			left: 45px;
			top: 68px;
		}
	}
}

.craneIcon {
	width: 17px;
}

.rowHead {
	svg {
		display: none;
	}
}

.distHeader {
	display: none;
}

@include xl {
	.mobileTable {
		margin-bottom: 0;

		thead {
			display: none;
		}

		.distributor {
			display: flex;
			flex-direction: column;
			position: relative;

			td {
				display: none;
				padding: 6px 24px;
				background: #EAF1FE;
				color: #101010;
				font-family: Rubik;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 19px;

				&.rowHead {
					background: #fff;
					display: flex;
					align-items: center;
					flex-direction: row-reverse;
					justify-content: space-between;
					border-bottom: 1px solid #e0ecfe;
					color: #242530;
					font-family: Rubik;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 19px;
					padding: 16px 24px;

					svg {
						display: block;
					}
				}

				&:nth-child(2) {
					padding-top: 12px;
				}

				&:nth-child(4) {
					padding-bottom: 12px;
				}

				.distHeader {
					display: inline-block;
					width: 80px;
					color: #7AB7FF;
					font-family: Rubik;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 19px;
				}
			}

			&.open {
				td {
					display: flex;

					&.rowHead {
						background: transparent;

						svg {
							transform: rotateZ(180deg);
						}
					}
				}

				&:last-child {
					td:first-child {
						border-radius: 0;
					}
				}
			}

			&:last-child {
				td:first-child {
					border-bottom-left-radius: 5px;
					border-bottom-right-radius: 5px;
				}

				td:nth-child(4) {
					border-bottom: none;
					border-bottom-left-radius: 5px;
					border-bottom-right-radius: 5px;
				}
			}

			&:first-child {
				td.rowHead {
					border-top-left-radius: 5px;
					border-top-right-radius: 5px;
				}
			}
		}
	}
}
