.modal {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06),
		2px 0px 10px rgba(0, 0, 0, 0.06);
}

.modalBody {
	display: grid;
	grid-template-rows: 1fr 4fr;
	align-items: center;
	justify-content: center;
}

.title {
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 30px;
}

.modalForm {
	display: grid;
	grid-template-rows: repeat(1fr, 4);
	align-items: center;
	justify-items: center;
	gap: 15px;
}

.modalForm > div {
	display: inline-flex;
	min-width: 100px;
	font-weight: 500;
	font-size: 14px;
}

.key {
	width: 50px;
	text-align: right;
	color: #7ab7ff;
	font-size: 14px;
	font-weight: 500;
}

.button {
	width: max-content;
}

.modalForm input[type='text'],
.modalForm select {
	height: 32px;
	width: 166px;
	border: 1px solid #a8abb7;
	border-radius: 5px;
	margin-right: 15px;
}

.exitButton {
	text-align: left;
	margin: 10px 0 0 10px;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #a8abb7;
}
