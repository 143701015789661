@import './../../../css/mixins.scss';

// .modal {
// 	display: flex;
// 	min-height: 200px;
// 	align-items: center;
// 	justify-content: center;
// 	flex-direction: column;
// 	padding: 10px;
// }

.modal {
	@include xl {
		padding: 15px;

		div {
			max-width: none;
			width: 100%;
			margin: 0;
		}

		p {
			font-weight: bold;
		}
	}
}

.close {
	@include xl {
		padding: 0;

		button {
			margin: 0 auto 0 0 !important;

			span:first-child {
				position: absolute;
				top: -1px;
				left: 9px;
				font-size: 2em;
				font-weight: normal;
			}
		}
	}
}


p {
	text-align: center;
	font-weight: 500;
}

.buttons {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-items: center;
	flex-wrap: wrap;
	justify-content: space-around;

	@include xl {
		.button:last-child {
			color: white;
			background-color: #007bff;
		}
	
		.button:first-child {
			color: #007bff;
			border-color: #007bff;
		}
	}
}

.button {
	width: max-content;

	@include xl {
		width: 100px;
	}
}
