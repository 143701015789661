@import './../../css/mixins.scss';

.modal {
	overflow: hidden;
	text-align: center;
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
}

.header button {
	@include xl {
		margin: -1em auto -1em -1em !important;
	}
}

.title {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

.buttons {
	margin-bottom: 30px;
}
button {
	margin: 10px;
	width: 60px;
	height: 35px;
	border: 1px solid gray;
	border-radius: 5px;
	background-color: white;
}

.exitButton {
	float: left;
	width: 10px;
}
