@import '../../css/mixins.scss';

::-webkit-scrollbar {
	@include xl {
		display: none;
	}
}

.sidebar {
	position: fixed;
	margin: 0;
	padding: 0;
	width: 14%;
	background-color: #242530;
	height: 100%;
	right: 0;
	text-align: start;
	font-weight: normal;
	line-height: 15px;
	color: white;

	@include xl {
		display: flex;
		flex-direction: column;
		width: 288px;
		z-index: 3;
		right: -288px;
		height: calc(100% - 3.5rem);
		transition: right 0.4s;
	}

	&.open {
		box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.09), 0px 1px 18px rgba(0, 0, 0, 0.08), 0px 3px 5px rgba(0, 0, 0, 0.16);
		right: 0px;
		transition: right 0.4s;
	}

	.header {
		display: none;
		flex-direction: column;

		.closeBtn {
			background: transparent;
			border: 0;
			padding: 16px;
			margin: 0;
			width: auto;
			height: auto;
			box-sizing: border-box;
			align-self: flex-start;

			.closeIcon {
				path {
					stroke: #badaff;
				}
			}
		}

		.profile {
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
			padding: 16px;
			border-bottom: 1px solid #434558;

			.name {
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 21px;
				color: #ffffff;
			}

			.icon {
				margin-left: 16px;
			}
		}

		@include xl {
			display: flex;
		}
	}

	.mobileScroll {
		@include xl {
			flex: 1;
			overflow-y: auto;
			padding-top: 32px;
			display: flex;
			flex-direction: column;
		}
	}
}

.backdrop {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0;
	// z-index: 0;
	transition: opacity 0.2s;

	&.open {
		opacity: 0.4;
		z-index: 2;
		transition: opacity 0.2s;
	}
}

.sidebarHeader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 5rem;

	img {
		width: calc(83%);
	}

	@include xl {
		display: none;
	}
}

.mobileHeader {
	width: 100%;
	height: 3.5rem;
	display: none;
	flex-direction: row-reverse;
	justify-content: flex-end;
	align-items: center;
	background: #242530;
	box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.1), 0px -1px 10px rgba(0, 0, 0, 0.12), 0px -2px 4px rgba(0, 0, 0, 0.16);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	color: #fff;
	padding: 0 6px 0 16px;

	@include xl {
		display: flex;
	}

	.title {
		font-family: Rubik;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
		margin-right: 17px;
		display: flex;
		max-width: 85%;

		> span {
			padding-left: 20px;
			color: #6D708A;
			position: relative;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: block;

			&:not(:last-child) {
				&:before {
					content: '/';
					display: block;
					position: absolute;
					left: 6px;
					top: 0;
				}
			}

			&:last-child {
				color: #fff;
				text-overflow: unset;
			}
		}
	}

	.menuBtn {
		background: transparent;
		border: 0;
		padding: 10px;
		margin: 0;
		width: auto;
		height: auto;
		box-sizing: border-box;

		.menuIcon {
			path {
				stroke: #fff;
			}
		}
	}
}

.org {
	display: flex;
	align-items: flex-end;
	width: 100%;
}

.org img {
	filter: brightness(0) invert(1);
	width: 20px;
	height: 20px;
	margin: 0 10px 0 5px;

	@include xl {
		display: none;
	}
}

.companies {
	padding-right: 32px;
	color: #a8abb7;
	font-size: 12px;
	font-weight: bolder;
	margin-top: 16px;
}

.sidebar a {
	display: flex;
	color: white;
	padding: 16px 28px;
	text-decoration: none;
	align-items: center;
	width: 100%;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 21px;

	@include xl {
		&:hover,
		&.activeHome {
			background-color: #333745;
			color: #7ab7ff !important;
			position: relative;

			border-right: 3px solid #3468ff;
			background: #333745 !important;
		}

		&:hover {
			&:after {
				display: none;
			}
		}
	}
}

.sidebar a:hover {
	background-color: #242530;
	color: #7ab7ff;

	@include xl {
		color: white;
	}
}

.orgActive,
.companyActive,
.siteActive,
.craneActive {
	background-color: #333745;
	color: white;
	width: 100%;
	border-right: 3px solid #3468FF;
}
.companyActive {
	@include xl {
		color: white !important;
		background-color: #333745 !important;
	}
}
.siteActive {
	@include xl {
		color: white !important;
		background-color: #333745 !important;
	}
}
.companyActive svg , .siteActive svg {
	path {
		fill: white !important;
	}
}
.craneActive {
	@include xl {
		background-color: #333745 !important;
		color: #fff !important;
	}
}

.activeNav svg{
	transform: rotate(-90deg);
}

.sidebar div:hover {
	background-color: #242530;
	color: #7ab7ff;

	@include xl {
		background: inherit;
		color: inherit;
	}
}
.sidebar div:active {
	background-color: #333745;
	color: #7ab7ff;

	@include xl {
		background: inherit;
		color: inherit;
	}
}
.company .navLink {
	width: 100%;
	padding-right: 30px;

	@include xl {
		color: #fff;
	}
}
.company .companyChildrenActive {
	@include xl {
		color: #7ab7ff;
	}
}
.company .companyChildrenActive svg , .site .siteChildrenActive svg {
	path {
		fill: #7ab7ff;
	}
}
.site .siteChildrenActive {
	@include xl {
		color: #7ab7ff;
	}
}
.company svg , .site svg{
	display: none;
	margin-left: 10px;
	width: 15px;
	height: 15px;

	@include xl {
		display: block;
	}
}
.site .navLink {
	width: 100%;
	padding-right: 45px;
}
.crane .navLink {
	width: 100%;
	padding-right: 60px;
	
	@include xl {
		background-color: #2D2F3C;
		color: #A8ABB7;
	}
}

.footerSidebar {
	border-top: 1px solid #434558;
	width: 13%;
	display: inline-flex;
	position: fixed;
	bottom: 5px;
	justify-content: right;
	padding-right: 15px;
	align-items: center;
	font-weight: 100;
	height: 30px;

	@include xl {
		// margin-top: 16px;
		position: absolute;
		width: 100%;
		height: auto;
		padding: 20px;
		background: #242530;
		bottom: 0;
	}

	.addNewItem {
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 19px;
	}

	.plusIcon {
		width: 16px;
		height: 16px;
		margin-left: 8px;

		path {
			stroke: #fff;
		}
	}
}

.footerSidebar img {
	filter: brightness(0) invert(1);
	width: 20px;
	height: 20px;
	margin-left: 5px;
}

.nestedList {
	max-height: 81%;
	overflow-y: auto;

	@include xl {
		max-height: unset;
		overflow-y: visible;
		flex: 1;
		padding-bottom: 70px;
	}
}

/* ::-webkit-scrollbar {
	width: 12px;
	height: auto;
}


::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}


::-webkit-scrollbar-thumb {
	background: linear-gradient(#0051ff, #0041ce, #11378a);
	border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
	background: #417dff;
} */

@keyframes openSidebar {
	0% {
		right: -288px;
	}
	100% {
		right: 0px;
	}
}
