@import './../../../../css/mixins.scss';

.card {
	border-radius: 10px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin: 15px 15px 0 0;

	@include xl {
		margin: 15px;
		border-radius: 5px;
		padding: 0;
	}
}

.table {
	margin: 30px;
}

.decstopTable {
	@include xl {
		display: none;
	}
}

.tableHeader {
	display: none;
	margin: 15px;

	div:first-child {
		margin-left: 15px;
	}
	div:last-child {
		margin-right: auto;
	}

	div {
		display: flex;
	}

	svg {
		width: 22px;
		height: 22px;
	}

	p {
		margin: 0 5px 0 0;
	}

	@include xl {
		display: flex;
	}
}

.cardHeader {
	display: flex;
	font-size: 16px;
	line-height: 19px;
	text-align: right;
	color: #303030;
	margin-bottom: 30px;

	@include xl {
		display: none;
	}
}

.mobileTable {
	display: none;

	svg {
		transform: rotate(-90deg);
		path {
			stroke: #A8ABB7;
		}
	}

	tr {
		display: flex;
		flex-direction: column;
	}

	tr:last-child {
		td:first-child {
			border: none;
		}
	}


	td {
		display: none;
		background-color: #EAF1FE;
		padding: 8px 20px 8px 26px;
	}

	td:first-child {
		align-items: center;
		display: flex !important;
		justify-content: space-between;
		background: none;
		padding: 10px 20px 10px;
		border-bottom: 1px solid #EAF1FE;

		span:first-child {
			color: black;
			width: max-content;
		}
	}

	span:first-child {
		font-weight: bold;
		display: inline-block;
		width: 60px;
		color: #7AB7FF;
	}

	.open {
		td:first-child svg {
			transform: rotate(90deg);
		}

		td {
			display: table-cell;
		}

		.removeUser {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 32.8px;
		
			svg {
				transform: none;
				path {
					stroke: #242530;
				}
			}
		}
	}

	@include xl {
		display: table;
	}
}

.cardTitle {
	float: right;
	width: 100%;
	font-weight: 500;
}

.addUserButton {
	display: inline-flex;
	float: left;
}

.title {
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

thead th {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #7ab7ff;
}

tbody td {
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: black;
}

.addCraneButton {
	display: inline-flex;
	float: left;
}

.button {
	float: left;
}

.moreIcon {
	width: 15px;
}

.moreActionsPopover {
	width: 133px;
	height: auto;
}

.moreActions .action {
	border-bottom: 1px solid #ececec;
	text-align: right;
	line-height: 30px;
	font-weight: 400;
	font-size: 14px;
}

.moreActions .action:hover {
	color: #3468ff;
	font-weight: 600;
}

.action span {
	margin-right: 5px;
}
