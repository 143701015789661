@import './../../../css/mixins.scss';

.modal {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06),
		2px 0px 10px rgba(0, 0, 0, 0.06);
}
.modal div:first-child {
	@include xl {
		max-width: 100%;
		height: 100%;
		margin: 0;
	}
}

.modalHeader {
	@include xl {
		display: none;
	}
}

.modalBody {
	@include xl {
		padding: 3em 0 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
	}
}

.formContainer {
	@include xl {
		height: 100%;
	}
}

.modalForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	flex-wrap: wrap;

	@include xl {
		height: 100%;
	}
}
.modalForm div {
	@include xl {
		margin-bottom: 15px;
	}
}
.modalForm div label {
	@include xl {
		margin-left: 10px;
		width: 70px !important;
		font-weight: bold;
	}
}
.modalForm div input {
	@include xl {
		width: 150px !important;
	}
}

.title {
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;

	@include xl {
		margin-bottom: 20px !important;
		height: auto !important;
	}
}

.detailsParagraph {
	min-height: 140px;
	font-size: 15px;
	margin: 0 15px 15px 0;
	text-align: right;
}

.address {
	@include xl {
		margin-bottom: 40px !important;
	}
}

.modalKey,
label {
	display: inline-block;
	width: 110px;
	font-weight: 500;
	font-size: 14px;
}

.button {
	width: max-content;

	@include xl {
		margin: 15px;
		background-color: #3468FF;
		color: white;
	}
}

.modalForm input[type='text'],
.modalForm input[type='tel'] {
	height: 32px;
	width: 120px;
	border: 1px solid #a8abb7;
	border-radius: 5px;
}

.exitButton {
	margin-right: 10px;
	width: 10px;
}

.modalFooter {
	@include xl {
		display: flex;
		justify-content: space-between;
		box-shadow: 0px -5px 5px -5px #A8ABB7;
		width: 100%;
		margin: auto 0 0 0 !important;
	}
}

.closeMobile {
	display: none;
	padding: 0;

	@include xl {
		display: inline-block;
	}
}
.closeMobile p {
	margin: 1.3em 1em 0 0;
	color: #A8ABB7;
	font-weight: bold;
}
.closeMobile button {
	position: relative;
    top: -15px;
    right: 20px;
}
.closeMobile span {
	display: none;
}
