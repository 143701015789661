@import './../../../../css/mixins.scss';

.wrapper {
	display: grid;
	grid-template-columns: 5fr 1fr;

	@include xl {
		margin: 0 15px 0;
		display: flex;
    	flex-direction: column-reverse;
	}
}

.card {
	direction: rtl;
	border-radius: 10px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin: 15px 15px 0 0;

	@include xl {
		margin: 0;
		border-radius: 5px;
		padding: 0;
	}
}

.table {
	margin: 30px;
}

.decstopTable {
	@include xl {
		display: none;
	}
}

.tableHeader {
	display: none;
	margin: 35px 0 10px 0;

	div:first-child {
		margin-left: 15px;
	}

	div {
		display: flex;
	}

	svg {
		width: 22px;
		height: 22px;
	}

	p {
		margin: 0 5px 0 0;
	}

	@include xl {
		display: flex;
		justify-content: flex-end;
	}
}

.cardHeader {
	display: flex;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: right;
	color: #303030;

	@include xl {
		display: none;
	}
}

.mobileTable {
	display: none;
	margin: 0;

	svg {
		transform: rotate(-90deg);
		path {
			stroke: #A8ABB7;
		}
	}

	th {
		font-weight: bold;
		vertical-align: inherit !important;
		text-align: right;
	}

	td {
		padding-top: 1.1em !important;
	}

	.open {
		display: table-row !important;
	
		span:first-child {
			color: #7AB7FF;
			margin-left: 10px;
		}
	}
	
	.tableExpand {
		display: none;
		background-color: #EAF1FE;
	
		div {
			display: flex;
		}
	
		span {
			width: 90%;
    		margin: 5px 0 5px 0;
		}

		span:first-child {
			font-weight: bold;
			width: 35%;
		}
	}

	.expandButton {
		text-align: left;
		padding-top: 10px !important;
	}

	.biding {
		padding-right: 1.3em !important;
	}

	@include xl {
		display: table;
	}
}

.mobileButton {
	position: relative;

	img {
		margin: 0 auto 0 6px;
		height: 15px;
	}

	.controls {
		flex-direction: column;
		background: #FFFFFF;
		padding: 0;
		box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.09), 0px 1px 18px rgba(0, 0, 0, 0.08), 0px 3px 5px rgba(0, 0, 0, 0.16);
		height: auto;
		width: 140px !important;
		position: absolute;
		z-index: 3;
		top: 14px;
		left: 16px;
		display: none !important;

		div {
			padding: 0 10px 10px 0;
		}

		svg {
			margin: 5px auto 0 5px;
			width: 24px !important;
			height: 24px !important;

			path {
				stroke: black;
			}
		}

		.centralDiv {
			border-bottom: 1px solid #EAEAEA;
			border-top: 1px solid #EAEAEA;
			margin: 0 0 10px 0;
			padding: 10px 10px 10px 0;
		}

		span {
			margin: 0 !important;
			font-weight: bold !important;
			width: 100% !important;
			color: #242530 !important;
		}
	}

	.openControls {
		display: flex !important;
	}
}


.cardTitle {
	float: right;
	width: 100%;
	margin-bottom: 30px;
}

thead th {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #7ab7ff;
}

tbody td {
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: black;
}

.moreIcon {
	float: left;
	width: 15px;
	align-items: flex-start;
	padding-bottom: 20px;

	@include xl {
		padding-bottom: 0;
	}
}

.vectorIcon {
	width: 15px;
	float: center;
	padding-bottom: 20px;
}

.popover {
	height: 30px;
	width: 120px;
	text-align: right;
	padding: 3px;
}

.popover:hover {
	font-weight: 500;
}

/*****************************************************************************************/

.licenseAmountCard {
	direction: rtl;
	border-radius: 10px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	margin: 15px 15px 0 0;
	height: max-content;

	@include xl {
		border-radius: 5px;
		margin: 0;
		font-weight: bold;
	}
}

.licenseAmountCardTitle {
	padding: 15px;
	float: right;
	width: 100%;
	margin-bottom: 15px;

	@include xl {
		margin: 0;
	}
}

.licenseAmountCardBody {
	padding: 15px;

	@include xl {
		display: flex;
	}
}

.licenseAmountCardFooter {
	display: flex;
	width: 100%;
	background-color: #e0ecfe;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	height: 50px;
	align-items: center;
	justify-content: center;

	@include xl {
		font-weight: normal;
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
	}
}

.licenseAmountCardFooter:hover {
	font-weight: 500;
}

.licenseAmount {
	display: inline-flex;
	width: 100%;

	@include xl {
		justify-content: center;
	}
}

.licenseAmountKey {
	color: #7ab7ff;
	width: 20%;

	@include xl {
		justify-content: center;
		margin-left: 10px;
		width: auto;
	}
}
.licenseAmountValue {
}
