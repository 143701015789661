@import '../../../css/mixins.scss';

.main {
	display: flex;
	flex-direction: column;
	justify-items: center;
}

.loadButton {
	min-width: 300px;
	border: 1px solid rgb(0, 102, 255);
	border-radius: 1px;
	margin: 15px;
	align-self: center;
}

.loadButton:hover {
	background-color: rgb(117, 165, 236);
}

.loading {
	align-self: center;
}
