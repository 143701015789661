@import '../../../css/mixins.scss';

.card {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin-top: 30px;

	@include xl {
		box-shadow: none;
		margin-top: 16px;
	}
}

.table {
	font-size: 15px;
	border: none;
}

.title {
	font-weight: 500;
	text-align: center;
	font-size: 14px;
	margin-top: 10px;

	@include xl {
		font-family: Rubik;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		color: #242530;
		margin-bottom: 24px;
		margin-top: 16px;
	}
}

.activityGraph {
	margin: 15px;

	hr {
		margin-bottom: 8px;
	}

	> div:nth-child(2) {
		margin: 0 15px;
	}

	@include xl {
		margin: -15px !important;
	}
}

.activityHistory {
	min-height: 20rem;
	margin: 15px;

	@include xl {
		min-height: 0;
	}
}

.activityTable {
	min-height: 32rem;

	@include xl {
		min-height: 0;
		margin-left: -15px;
		margin-right: -15px;
		margin-bottom: -15px;
		overflow: hidden;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
}

thead tr {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #c1c1c1;
}

.chart {
	border: none;
}

thead {
	border-bottom: 1px solid lightgray;
}

.footerCardLine {
	display: flex;
	flex-direction: row;

	@include xl {
		flex-direction: row-reverse;
		align-items: center;
		// padding-bottom: 8px;
		padding: 0 20px 8px 20px;
	}
}

.footerYear {
	flex-basis: 10%;
	margin-right: 1rem;

	@include xl {
		margin-right: 0;
	}
}

.footerTitle {
	font-weight: 500;
	text-align: center;
	font-size: 14px;
	margin-bottom: 10px;
	flex-basis: 75%;

	@include xl {
		margin-bottom: 0;
		margin-left: 40px;
		text-align: left;
	}
}

.wrapperCraneActivity {
	@include xl {
		width: 100vw;
		overflow: hidden;
		padding: 0 15px;
	}
}

.mobileReverse {
	@include xl {
		display: flex;
		flex-direction: column-reverse;
	}
}

.activityTitle {
	display: none;

	@include xl {
		display: block;
		color: #7AB7FF;
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		margin-bottom: 16px;
	}
}

.mobileActivityTable {
	@include xl {
		margin-top: 0;
		margin-bottom: 0;

		thead {
			display: none;
		}

		tbody {
			display: flex;
			flex-direction: column;
		}
	}
}

.labelInfo {
	display: none;

	@include xl {
		display: flex;
		align-items: center;
		border-top: 1px solid #E0ECFE;

		td {
			padding: 16px;

			&:first-child {
				flex: 1;
				color: #242530;
				font-family: Rubik;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 19px;
			}

			&:last-child {
				svg {
					transform: rotateZ(-90deg);

					&.open {
						transform: rotateZ(90deg);
					}

					path {
						stroke: #A8ABB7;
					}
				}
			}
		}
	}
}

.hiddenInfo {
	@include xl {
		flex-direction: column;
		background: #EAF1FE;
		display: none;

		td {
			padding: 6px 16px;
			display: flex;

			span {
				&:first-child {
					width: 90px;
				}

				&:last-child {
					font-family: Rubik;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 19px;
					color: #101010;
				}
			}

			&:first-child {
				padding-top: 12px;
			}

			&:last-child {
				padding-bottom: 12px;
			}
		}

		&.open {
			display: flex;

		}
	}
}

.hiddenTitle {
	display: none;

	@include xl {
		display: block;
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #7AB7FF;
	}
}