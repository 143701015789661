@import '../../../css/mixins.scss';

.notificationCard {
	display: grid;
	grid-template-columns: 10% 70% 20%;
	border-radius: 5px;
	background-color: #ffffff;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06), 2px 0px 4px rgba(0, 0, 0, 0.06);
	margin: 25px 15px 0 15px;

	@include xl {
		margin: 16px 0 0 0;
		background: #FFFFFF;
		border-radius: 4px;
		box-shadow: none;
		display: flex;
		flex-direction: column;
		border: none;
		position: relative;
	}
}

.rightSectionControl {
	background-color: #f9f9f9;
	border-right: 5px solid #ff5143;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 100%;
	padding-right: 10px;

	.alertIcon {
		display: none;
	}

	.moreIcon {
		display: none;
	}

	@include xl {
		border-right: none;
		border-radius: 0;
		background: #E0ECFE;
		padding: 8px;
		display: flex;
		align-items: center;
		position: relative;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;

		.crane {
			font-family: Rubik;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;
			color: #101010;
			margin-left: 20px;
			position: relative;

			&:before {
				content: '|';
				position: absolute;
				left: -11px;
				top: 0;
			}
		}

		.site {
			font-family: Rubik;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 17px;
			color: #101010;
		}

		.alertIcon {
			display: block;
			width: 14px;
			height: 14px;
			margin-left: 10px;
		}

		.moreIcon {
			display: block;
			position: absolute;
			top: 4px;
			left: 4px;
			width: 24px;
			height: 24px;
			padding: 4px;
		}
	}
}

.rightSectionControl .crane,
.rightSectionControl .site {
	font-size: 15px;
}
.rightSectionControl .site {
	font-weight: 500;
}

.centerSectionControl {
	font-size: 15px;
	padding-right: 10px;
	height: 100%;
	border-left: 1px solid lightgray;

	@include xl {
		border-left: none;
		padding: 8px;

		.content {
			font-family: Rubik;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 17px;
			color: #101010;
			margin-bottom: 8px;
		}

		.time {
			font-family: Rubik;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 17px;
			color: #A8ABB7;
		}
	}
}

.leftSectionControl {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	justify-items: center;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	height: 100%;
	margin: 0 10px 0 10px;

	.close {
		display: none;
	}

	@include xl {
		position: absolute;
		top: 8px;
		left: 8px;
		flex-direction: column;
		margin: 0;
		padding: 12px 12px 12px 50px;
		background: #FFFFFF;
		box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.09), 0px 1px 18px rgba(0, 0, 0, 0.08), 0px 3px 5px rgba(0, 0, 0, 0.16);
		border-radius: 4px;
		height: auto;
		width: auto;
		display: none;

		&.open {
			display: flex;
			z-index: 3;
		}

		> div {
			margin-bottom: 12px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.close {
			display: block;
			position: absolute;
			top: 4px;
			left: 4px;
			width: 18px;
			height: 18px;
			// padding: 2px;

			path {
				stroke: #101010;
				stroke-width: 1;
			}
		}
	}
}

.icons {
	width: 20px;
	height: 20px;
}

.switch {
	display: inline-flex;
	align-items: center;

	@include xl {
		flex-direction: row-reverse;
	}
}
