.craneNotifications {
	padding: 20px;
}

.notificationCard {
	display: grid;
	grid-template-columns: 85% 15%;
	align-items: center;
	border-radius: 5px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-right: 10px solid #ff5143;
	height: 48px;
	font-size: 14px;
	background-color: white;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
}

.content {
	margin-right: 10px;
	font-weight: 400;
}
.time {
	color: rgb(185, 185, 185);
}

.headerBody {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
