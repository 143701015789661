@import '../../../css/mixins.scss';

.title {
	@include xl {
		color: #242530;
		font-family: Rubik;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		margin: 0 0 32px 0;
	}
}

.modalWrapper  {
	@include xl {
		padding-left: 0 !important;

		> div {
			margin: 1.75rem auto;
		}
	}

	@include sm {
		> div {
			margin: 16px;
		}
	}
}

.buttons {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-items: center;
	flex-wrap: wrap;
	justify-content: space-around;

	@include xl {
		justify-content: center;
	}
}

.button {
	width: max-content;

	@include xl {
		background: #3468FF;
		border-radius: 4px;
		color: #fff;
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		padding: 10px 30px;

		&.close {
			background: #fff;
			color: #3468FF;
			border: 1px solid #3468FF;
			margin-right: 32px;
		}
	}
}

.customClose {
	display: none;

	@include xl {
		display: flex;
		padding: 8px 8px 0 8px;

		svg {
			width: 24px;
			height: 24px;

			path {
				stroke: #242530;
			}
		}
	}
}

.defaultClose {
	@include xl {
		display: none;
	}
}
