@mixin sm {
    @media screen and (max-width: 479px) {
        @content;
    }
}

@mixin md {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@mixin lg {
    @media screen and (max-width: 991px) {
        @content;
    }
}

@mixin xl {
    @media screen and (max-width: 1199px) {
        @content;
    }
}


