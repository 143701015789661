@import '../../../css/mixins.scss';

.notificationCard {
	border-radius: 5px;
	background-color: #f9f9f9;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	margin: 15px 15px 0 0;

	@include xl {
		margin-right: 0;
	}
}
.notificationCard .card {
	@include xl {
		margin: 0;
		background: #FFFFFF;
		border-radius: 4px;
		box-shadow: none;
		display: flex;
		flex-direction: column;
		border: none;
		position: relative;
		max-width: 100%;
	}
}

.right , .left{
	@include xl {
		padding: 0;
		max-width: 100%;
	}
}

.rightSection {
	border-right: 10px solid #ff5143;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 100%;
	padding-right: 10px;

	.alertIcon {
		display: none;
	}

	@include xl {
		border-right: none;
		border-radius: 0;
		background: #EBECF4;
		padding: 8px;
		display: flex;
		align-items: center;
		position: relative;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;

		.crane {
			font-family: Rubik;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;
			color: #101010;
			margin-left: 20px;
			position: relative;

			&:before {
				content: '|';
				position: absolute;
				left: -11px;
				top: 0;
			}
		}

		.site {
			font-family: Rubik;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 17px;
			color: #101010;
		}

		.alertIcon {
			display: block;
			width: 14px;
			height: 14px;
			margin-left: 10px;
		}
	}
}

.rightSection .crane {
	font-size: 15px;
	font-weight: 500;

	@include xl {
		font-weight: bold;
	}
}
.rightSection .site {
	font-size: 15px;
}

.leftSection {
	font-size: 15px;
	padding-right: 10px;
	background-color: white;
}
.leftSection .time {
	color: rgb(185, 185, 185);
	display: inline-block;
	float: left;
	padding-left: 30px;

	@include xl {
		display: block;
		float: none;
	}
}
.leftSection {
	font-size: 15px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	background-color: white;
	height: 100%;
	margin-right: 25px;
	padding-top: 12px;

	@include xl {
		margin: 0;
		padding: 8px;

		.content {
			font-family: Rubik;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 17px;
			color: #101010;
			margin-bottom: 8px;
		}

		.time {
			font-family: Rubik;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 17px;
			color: #A8ABB7;
		}
	}
}

.leftSection .content {
	display: inline-block;

	@include xl {
		display: block;
	}
}