@import '../../../css/mixins.scss';

.card {
	margin: 30px 0 0 0;
	border-radius: 10px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin: 15px 15px 0 0;

	@include xl {
		margin: 0;
		margin-top: 16px;
		box-shadow: none;
		border-radius: 5px;
		padding: 16px;
		position: relative;
	}
}

.table {
	margin: 30px;
}

.modal {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);

	> div:first-child {
		@include xl {
			max-width: 100%;
			// height: 100%;
			margin: 0;
			border-radius: 0;
		}
	}

	.modalHeader {
		@include xl {
			display: none;
		}
	}

	.modalBody {
		.modalForm {
			@include xl {
				flex: 1;
			}
		}

		@include xl {
			padding: 3em 0 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100vh;
			position: relative;
		}
	}

	.modalFooter {
		@include xl {
			display: flex;
			justify-content: space-between;
			box-shadow: 0px -5px 5px -5px #a8abb7;
			width: 100%;
			margin: auto 0 0 0 !important;
			border-radius: 0;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}

	.closeMobile {
		display: none;
		padding: 0;

		@include xl {
			display: inline-block;
		}
	}
	.closeMobile p {
		margin: 15px 1em 0 0;
		color: #a8abb7;
		font-weight: bold;
	}
	.closeMobile button {
		position: relative;
		top: -15px;
		right: 20px;
	}
	.closeMobile span {
		display: none;
	}
}

.detailsForm {
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;

	@include xl {
		.row {
			margin: 10px 0;

			label {
				color: #7AB7FF;
				font-family: Rubik;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 19px;
			}

			input {
				color: #242530;
				font-family: Rubik;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 17px;
				padding: 8px 10px;
			}

			&.topMargin {
				margin-top: 38px;
			}
		}
	}
}

.cardTitle {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: right;
	color: #303030;

	@include xl {
		font-family: Rubik;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 16px;
		color: #7ab7ff;
		// margin-bottom: 20px;
		display: block;
		margin-bottom: 30px;
	}
}

.title {
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;

	@include xl {
		margin-bottom: 38px;
	}
}

thead th {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #7ab7ff;
}

tbody td {
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: black;
}

.detailsParagraph {
	min-height: 140px;
	font-size: 15px;
	margin: 0 20% 15px 0;
	text-align: right;
}

.detailsKey,
label {
	display: inline-block;
	width: 100px;
	font-weight: 500;
	font-size: 14px;
}

.button {
	width: max-content;
	align-self: center;

	@include xl {
		margin: 10px 15px;
		background-color: #3468FF;
		color: white;
	}
}

input[type='text'],
input[type='tel'] {
	height: 32px;
	width: 150px;
	border: 1px solid #a8abb7;
	border-radius: 5px;
}

.exitButton {
	margin-right: 10px;
	width: 10px;
}

.icon {
	width: 16px;
	height: 16px;
	align-self: start;

	@include xl {
		opacity: 0.5;
		margin: -5px;
		padding: 5px;
		width: 26px;
		height: 26px;
	}
}

@include xl {
	.mobileTable {
		margin-bottom: 0;
		display: flex;

		thead {
			width: 100px;
			border-bottom: none;

			tr {
				display: flex;
				flex-direction: column;

				th {
					padding: 10px 0;
					color: #101010;
					font-family: Rubik;
					font-style: normal;
					font-weight: 600;
					font-size: 16px;
					line-height: 19px;
					min-height: 39px;

					&:last-child {
						display: none;
					}
				}
			}
		}

		tbody {
			flex: 1;

			tr {
				display: flex;
				flex-direction: column;

				td {
					padding: 10px 0;
					color: #101010;
					font-family: Rubik;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 19px;
					min-height: 39px;

					&.tcontrols {
						padding: 0;
						margin: 0;
						position: absolute;
						left: 16px;
						top: 16px;
					}
				}
			}
		}
	}
}
