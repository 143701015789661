@import './../../css/mixins.scss';

.steakyHeader {
    @include xl {
        position: fixed;
        width: 100%;
        left: 0;
        z-index: 1;
        padding: 0 32px;
        color: #7AB7FF;
        background-color: white;
        top: 56px;
        height: 36px;
        display: flex;
        align-items: center;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px -2px 4px rgba(0, 0, 0, 0.16);
        animation: open 0.5s ease-in-out;
        font-size: 14px !important;
    }
}

@keyframes open {
    0% {
        top: 0;
    }
    100% {
        top: 57px;
    }
}