@import '../../../css/mixins.scss';

.card {
	margin: 30px 0 0 0;
	border-radius: 10px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin: 15px 15px 0 0;

	@include xl {
		margin: 0;
		margin-top: 40px;
		box-shadow: none;
		padding: 0;
		border-radius: 5px;
	}
}

.table {
	margin: 30px;
}

.cardTitle {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: right;
	color: #303030;

	@include xl {
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #7AB7FF;
		padding: 16px;
		display: block;
	}
}

.title {
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

thead th {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #7ab7ff;
}

tbody td {
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: black;
}

.tname {
	svg {
		display: none;
	}
}


@include xl {
	.mobileTable {
		margin-bottom: 0;
		overflow: hidden;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;

		.thead {
			display: none;
		}

		.tbody {
			display: flex;
    		flex-direction: column;
		}

		.tcard {
			display: flex;
			flex-direction: column;
			background: white;
			width: 100%;
			border-radius: 5px;

			> td {
				padding: 10px 16px;
				font-family: Rubik;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 19px;
				color: #303030;
				background: #EAF1FE;
				display: none;
			}

			.tname {
				padding: 16px;
				border-top: 1px solid #DBDCE8;
				color: #242530;
				font-family: Rubik;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 19px;
				display: block;
				background: #fff;
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-direction: row-reverse;

				svg {
					display: block;
				}
			}

			.theaders {
				color: #7AB7FF;
				display: inline-block;
				font-family: Rubik;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 19px;
				min-width: 120px;
			}

			&.open {
				.tname {
					svg {
						transform: rotateZ(180deg);
					}
				}

				> td {
					display: flex;
				}
			}
		}
	}
}
