.card {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin-top: 30px;
}
.title {
	font-weight: 500;
	text-align: center;
	font-size: 13px;
	margin-top: 10px;
}

.line,
.bar,
.pie {
	min-height: 40rem;
}
.headerCard {
	margin-bottom: 10px;
}
.bodyCardPie {
	margin-top: 40px;
}
.bodyCardLine {
	padding: 20px;
}
.footerCardLine {
}

.bodyCardBar {
	margin-top: 100px;
	margin-right: 10px;
}

ul .link {
	color: gray;
	font-size: 15px;
	margin-left: 15px;
}

ul .link:hover {
	color: black;
}

.notificationsCard {
	margin: 10px;
}

.headerCardNotification {
	padding: 10px;
	font-weight: 500;
}

/* .bodyCardNotification {
	padding: 10px;
} */

.notification {
	margin-bottom: 20px;
}
.footerTitle {
	font-weight: 500;
	text-align: center;
	font-size: 14px;
	margin-bottom: 10px;
}

.active {
	font-weight: 600 !important;
	color: black;
	text-decoration-line: underline;
	text-decoration-style: solid;
}
