.modal {
	display: flex;
	min-height: 200px;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 10px;
}

p {
	text-align: center;
	font-weight: 500;
}

.buttons {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-items: center;
	flex-wrap: wrap;
	justify-content: space-around;
}

.button {
	width: max-content;
}
