@import './../../../css/mixins.scss';

.modalContainer {
	@include xl {
		padding: 15px;

		div:first-child {
			margin: 0;
			width: 100%;
			max-width: none;
		}
	}
}

.modal {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding: 10px;
}

.close {
	display: none;

	@include xl {
		display: block;
		padding: 0;
		float: none;

		button {
			margin: 0 auto 0 0 !important;

			span:first-child {
				color: black;
				position: absolute;
				top: -1px;
				left: 9px;
				font-size: 2em;
				font-weight: normal;
			}
		}
	}
}

.modalTitle {
	margin-top: 40px;
	font-size: 20px;
	font-weight: bold;

	@include xl {
		display: none;
	}
}

.modalFooterTitle {
	margin-top: 40px;
	font-size: 14px;
	font-weight: bold;
	text-align: center;

	@include xl {
		margin-top: 20px;
		font-size: 20px;
	}
}

.modalContent {
	margin: 20px auto;
	font-weight: 200;
}

.button {
	@include xl {
		display: none;
	}
}
.animation {
	margin: 50px 50px 0 50px;

	@include xl {
		margin-top: 5px;
	}
}

.success_checkmark,
.error_checkmark {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	display: block;
	stroke-width: 2;
	animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
	position: relative;

	@include xl {
		width: 60px;
		height: 60px;
	}
}

.success_checkmark {
	stroke: #4bb71b;
	stroke-miterlimit: 10;
	box-shadow: inset 0px 0px 0px #4bb71b;
}
.error_checkmark {
	stroke: #dd1313;
	stroke-miterlimit: 10;
	box-shadow: inset 0px 0px 0px #dd1313;
}

.success_checkmark_circle,
.error_checkmark_circle {
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 2;
	stroke-miterlimit: 10;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.success_checkmark_circle {
	stroke: #4bb71b;
	fill: #fff;
}
.error_checkmark_circle {
	stroke: #dd1313;
	fill: #fff;
}

.checkmark_check {
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes scale {
	0%,
	100% {
		transform: none;
	}

	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}

@keyframes fill {
	100% {
		box-shadow: inset 0px 0px 0px 30px #4bb71b;
	}
}

/* ================================================================= */

.loader,
.loader:after {
	border-radius: 50%;
	width: 10em !important;
	height: 10em;
}
.loader {
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 1.1em solid rgba(143, 143, 143, 0.2);
	border-right: 1.1em solid rgba(143, 143, 143, 0.2);
	border-bottom: 1.1em solid rgba(143, 143, 143, 0.2);
	border-left: 1.1em solid #007bff;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.5s infinite linear;
	animation: load8 1.5s infinite linear;
}
@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
