@import '../../../css/mixins.scss';

.modalTitle {
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 40px;

	@include xl {
		height: 10%;
		margin: 25px 0 15px 0 !important;
		font-weight: bold;
		height: 5% !important;
	}
}

.editDetailsModal {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06),
		2px 0px 10px rgba(0, 0, 0, 0.06);

	@include xl {
		border-radius: 0;
		z-index: 10000;
	
		div:first-child {
			border-radius: 0;
			margin: 0;
			height: 100%;
			max-width: none;
		}
	}
}

.modalHeader {
	@include xl {
		display: none;
	}
}

.modalBody {
	@include xl {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0;
		height: 100%;
	}
}

.detailsParagraph,
.detailsForm {
	min-height: max-content;
	font-size: 15px;
}

.editDetailsModal input[type='text'],
.editDetailsModal input[type='tel'],
.editDetailsModal input[type='date'],
.editDetailsModal select {
	height: 32px;
	width: 150px;
	border: 1px solid #a8abb7;
	border-radius: 5px;

	@include xl {
		width: 180px !important;
	}
}

.editDetailsModal input[type='number'] {
	height: 32px;
	width: 115px;
	border: 1px solid #a8abb7;
	border-radius: 5px;
	margin: 0 2px 0 5px;
	align-self: right;

	@include xl {
		width: 150px !important;
	}
}

.editDetailsModal input[type='text']:focus,
.editDetailsModal input[type='tel']:focus,
.editDetailsModal input[type='date']:focus,
.editDetailsModal input[type='number']:focus,
.editDetailsModal select:focus {
	border: 2px solid #3468ff;
}

.exitButton {
	margin: 5px 10px 0 5px;
	width: max-content;
	align-self: flex-end;
	font-weight: 500;
	font-size: 14px;
	color: #a8abb7;
}

.modalForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;

	@include xl {
		border-radius: 0;
		height: 90%;
		width: 100%;

		div:first-child {
			margin: 10px !important;
		}

		div {
			height: auto !important;
			margin: 10px;
		}
	}
}


.detailsForm {
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
}

.detailsKey {
	float: right;
	align-self: center;
	width: 100px;
}

.paragraphTitle {
	font-weight: 500;
	font-size: 14px;
	color: #7ab7ff;
}

.tableDetailsKey {
	width: 90px;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #242530;
	margin-left: 10px;
}

.button {
	width: max-content;

	@include xl {
		margin: 10px 15px;
		background-color: #3468FF;
		color: white;
	}
}

.modalFooter {
	p {
		display: none;
		color: #A8ABB7;
		margin: auto 15px;

		@include xl {
			display: inline;
		}
	}

	@include xl {
		display: flex;
		justify-content: space-between;
		box-shadow: 0px -5px 5px -5px #A8ABB7;
		width: 100%;
		margin: auto 0 0 0 !important;
	}
}
