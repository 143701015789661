@import './../../css/mixins.scss';

.navbar {
	direction: rtl;
	height: 4rem;
	width: 87%;
	background-color: rgba(255, 255, 255, 0.712);

	@include xl {
		width: 100%;
		background: #fff;
		position: fixed;
		top: unset;
		bottom: 0;
		box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.16);
		height: 3.5rem;
		z-index: 3;
	}
}

.links {
	margin-right: auto;

	.nav {
		display: flex;
		justify-content: space-around;

		> a {
			@include xl {
				display: none;
			}
		}
	}

	@include xl {
		margin-right: unset;
		width: 100%;
	}
}

svg {
	width: 27px;
	height: 27px;
}

.notification {
	fill: orange;
}

.welcomeText {
	margin: 0 0 0 1rem;

	@include xl {
		display: none;
	}
}

.logout:hover {
	color: royalblue;
	font-weight: bold;
	text-decoration: underline;
	cursor: pointer;
}

/* .vr {
	border-right: 1px solid lightgray;
	margin-left: 15px;
	margin-bottom: 5px;
}
.currentUser {
	display: inline-block;
	font-size: 16px;
	font-weight: 500;
	margin: 3px 3px 3px 10px;
} */

.toolbarLink {
	margin-left: 15px;
	height: 27px;
	font-size: 24px;

	svg {
		vertical-align: initial;
	}

	@include xl {
		font-size: 16px;
		height: 20px;
		margin-left: 0;

		svg {
			width: 20px;
			height: 20px;

			path, circle {
				stroke: #A8ABB7;
				stroke-width: 1;
			}
		}

		&.active {
			svg {
				path, circle {
					stroke: #3468FF;
				}
			}
		}
	}
}
#rotate:hover {
	-webkit-animation: spin 4s linear infinite;
	-moz-animation: spin 4s linear infinite;
	animation: spin 4s linear infinite;

	@include xl {
		animation: none;
	}
}
#shake:hover {
	animation: shake 5s infinite;

	@include xl {
		animation: none;
	}
}

.active {
	font-weight: 600;
	color: red;
}

.notification {
	position: relative;
}

.notification .badge {
	position: absolute;
	top: 5px;
	left: 15px;
	border: 2px solid white;
	border-radius: 50%;
	background: red;
	color: white;
	min-width: 25px;
	max-height: 25px;
	font-size: 11px;
	text-align: center;
}

.mobileToolbar {
	display: none;

	@include xl {
		display: flex;
		flex-direction: row-reverse;
		width: 100%;
		justify-content: space-around;
		padding: 0 5%;

		a.active {
			svg path {
				stroke: #3468FF;
			}
		}
	}
}

@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes shake {
	10%,
	30%,
	50%,
	70%,
	90% {
		-webkit-transform: rotate(20deg);
		transform: rotate(20deg);
	}
	20%,
	40%,
	60%,
	80%,
	100% {
		-webkit-transform: rotate(-20deg);
		transform: rotate(-20deg);
	}
}
