@import './../../../css/mixins.scss';


.modal {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06),
		2px 0px 10px rgba(0, 0, 0, 0.06);

	@include xl {
		padding: 15px;

		div {
			max-width: none;
			width: 100%;
			margin: 0;
		}

		p {
			font-weight: bold;
		}
	}
}

.close {
	@include xl {
		padding: 0;

		button {
			margin: 0 auto 0 0 !important;

			span:first-child {
				position: absolute;
				top: -1px;
				left: 9px;
				font-size: 2em;
				font-weight: normal;
			}
		}
	}
}

.exitButton {
	text-align: left;
	margin: 10px 0 0 10px;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #a8abb7;
}

.modalBody {
	align-items: center;
	justify-content: center;
}

.title {
	text-align: center;
	font-size: 16px;
	font-weight: 500;

	@include xl {
		font-weight: bold;
		margin: 10px;
	}
}

.modalForm {
	margin-top: 20px;
	text-align: center;

	@include xl {
		button {
			width: 100px;
			color: white;
			background-color: #007bff;
		}
	}
}

.modalForm select {
	height: 32px;
	width: 142px;
	border: 1px solid #a8abb7;
	border-radius: 5px;

	@include xl {
		background-color: white;
		width: 200px;
		margin: 0 0 40px 0;
	}
}
