@import './../../../css/mixins.scss';

.card {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06),
		2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin-top: 30px;

	@include xl {
		margin-top: 16px;
		box-shadow: none;
		padding: 8px 0;

		&.transparent {
			background: 0;
			padding: 0;
		}
	}
}
.title {
	font-weight: 500;
	text-align: center;
	font-size: 14px;
	margin-top: 10px;
}

.line,
.bar,
.pie {
	min-height: 28rem;

	@include xl {
		min-height: 10rem;
	}
}
.headerCard {
	margin-bottom: 10px;
}
.bodyCardPie {
	margin-top: 40px;
}
.bodyCardLine {
	padding: 20px;
}
.footerCardLine {
}

.bodyCardBar {
	margin-top: 100px;
	margin-right: 10px;

	@include xl {
		margin-top: 24px;
		margin-right: 0;
	}
}

ul .link {
	color: gray;
	font-size: 18px;
	margin-left: 10px;
}

ul .link:hover {
	color: black;
}

.notificationsCard {
	margin: 10px;

	@include xl {
		margin: 0;
		background: transparent;
	}
}

.headerCardNotification {
	padding: 10px;
	font-weight: 500;

	@include xl {
		font-family: Rubik;
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 21px;
		margin-top: 28px;
		margin-bottom: 12px;
	}
}

/* .bodyCardNotification {
	padding: 10px;
} */

.notification {
	margin-bottom: 20px;
}

.footerCardLine {
	display: flex;
	flex-direction: row;
}
.footerYear {
	flex-basis: 10%;
	margin-right: 1rem;
}
.footerTitle {
	font-weight: 500;
	text-align: center;
	font-size: 14px;
	margin-bottom: 10px;
	flex-basis: 75%;
}
