@import '../../../css/mixins.scss';

.card {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin-top: 30px;
}

.title {
	font-weight: 500;
	text-align: center;
	font-size: 14px;
	margin-top: 10px;
}

.table {
	margin: 30px;

	@include xl {
		margin: 0;
		width: 100vw;
		overflow: hidden;

		> div {
			margin: 0;
		}
	}
}

thead th {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #7ab7ff;
}
