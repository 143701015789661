@import '../../../css/mixins.scss';

.modalWrapper  {
	@include xl {
		padding-left: 0 !important;

		> div {
			margin: 1.75rem auto;
		}
	}

	@include sm {
		> div {
			margin: 16px;
		}
	}
}

.modal,
.modal2 {
	display: flex;
	min-height: 200px;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 10px;

	@include xl {
		padding: 8px 24px 24px 24px;
		min-height: unset;
	}
}

.customClose {
	display: none;
}

p {
	text-align: center;
	margin-top: 10px;
}

.button {
	width: max-content;
	
	@include xl {
		background: #3468FF;
		border-radius: 4px;
		color: #fff;
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		padding: 10px 30px;
	}
}

.modal2 input[type='text'] {
	background-color: lightgray;
	border: none;
	height: 25px;
	margin-bottom: 5px;
	text-align: center;

	@include xl {
		border: 1px solid #A8ABB7;
		box-sizing: border-box;
		border-radius: 5px;
		background: #fff;
		padding: 15px;
		margin-bottom: 12px;
		font-family: Rubik;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 17px;
	}
}

.retrySms {
	font-size: 12px;

	@include xl {
		font-family: Rubik;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		text-decoration-line: underline;
		margin-bottom: 20px;
	}
}

.retrySms:hover {
	color: lightseagreen;
	text-decoration: underline;
}

@include xl {
	.title {
		font-family: Rubik;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #242530;
		margin-bottom: 16px;
	}

	.text {
		font-family: Rubik;
		font-style: normal;
		font-weight: normal;
		margin-top: 0;
		line-height: 19px;
		color: #242530;
	}

	.defaultClose {
		display: none;
	}

	.customClose {
		display: flex;
		padding: 8px 8px 0 8px;

		svg {
			width: 24px;
			height: 24px;

			path {
				stroke: #242530;
			}
		}
	}

	.exitButton {
		font-size: 28px;
		color: #242530;
		padding: 8px 6px 0 6px;
	}
}