@import './../../../css/mixins.scss';

.modal {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06),
		2px 0px 10px rgba(0, 0, 0, 0.06);

	@include xl {
		border-radius: 0;
		z-index: 10000;
	
		div:first-child {
			border-radius: 0;
			margin: 0;
			height: 100%;
			max-width: none;
		}
	}
}

.modalHeader {
	@include xl {
		display: none;
	}
}

.modalBody {
	display: grid;
	grid-template-rows: 1fr 4fr;
	align-items: center;
	justify-content: center;

	@include xl {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0;
	}
}

.title {
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 30px;

	@include xl {
		height: 10%;
		margin: 35px 0 25px 0 !important;
		font-weight: bold;
		height: 5% !important;
	}
}

.modalForm {
	display: grid;
	grid-template-rows: repeat(1fr, 4);
	align-items: center;
	justify-items: center;
	gap: 15px;

	@include xl {
		border-radius: 0;
		height: 90%;
		width: 100%;
		display: flex;
		flex-flow: column;

		div {
			height: auto !important;
			margin: 10px;

			div {
				font-weight: bold;
				align-self: center;
			}
		}
	}
}

.modalForm > div {
	display: inline-flex;
	min-width: 100px;
	font-weight: 500;
	font-size: 14px;

	@include xl {
		margin-top: 10px;
	}
}

.key {
	width: 70px;
	text-align: right;
	color: #7ab7ff;
	font-size: 14px;
	font-weight: 500;
}

.button {
	width: max-content;

	@include xl {
		margin: 10px 15px;
		background-color: #3468FF;
		color: white;
	}
}

.modalForm input[type='text'],
.modalForm select {
	height: 32px;
	width: 140px;
	border: 1px solid #a8abb7;
	border-radius: 5px;
	margin-right: 15px;

	@include xl {
		width: 180px !important;
	}
}

.exitButton {
	text-align: left;
	margin: 10px 0 0 10px;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #a8abb7;
}

.modalFooter {
	p {
		display: none;
		color: #A8ABB7;
		margin: auto 15px;

		@include xl {
			display: inline;
		}
	}

	@include xl {
		display: flex;
		justify-content: space-between;
		box-shadow: 0px -5px 5px -5px #A8ABB7;
		width: 100%;
		margin: auto 0 0 0 !important;
	}
}
