@import './../../../../css/mixins.scss';

.card {
	border-radius: 10px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin: 15px 15px 0 0;

	@include xl {
		margin-left: 15px;
		padding: 0;
		border-radius: 5px;
		box-shadow: none;
	}
}

.table {
	margin: 30px;
}

.decstopTable {
	@include xl {
		display: none;
	}
}

.cardHeader {
	display: flex;
	font-size: 16px;
	line-height: 19px;
	text-align: right;
	color: #303030;
	margin-bottom: 30px;

	@include xl {
		display: none;
	}
}

.tableHeader {
	display: none;
	margin: 0 15px 0 15px;

	@include xl {
		display: flex;
		margin-top: 10px;
	}
}
.tableHeader div {
	display: flex;
	align-items: center;
}
.tableHeader div:first-child {
	margin-left: 15px;
}
.tableHeader div:last-child {
	margin-right: auto;
}
.tableHeader svg {
	width: 16px;
	height: 16px;
}
.tableHeader p {
	margin: 0 5px 0 0;
}

.cardTitle {
	float: right;
	width: 100%;
	font-weight: 500;
}

.addCraneButton {
	display: inline-flex;
	float: left;
}

.title {
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

thead th {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #7ab7ff;
}

tbody td {
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: black;
}

.addCraneButton {
	display: inline-flex;
	float: left;
}

.button {
	float: left;
}

.mobileButton {
	display: none;
	padding: 0 !important;

	div {
		padding: 20px 0 0 18px;
	}

	img {
		margin-right: auto;
		height: 15px;
	}

	@include xl {
		display: table-cell;
	}
}

.moreIcon {
	width: 15px;
}

.moreActionsPopover {
	width: 133px;
	height: auto;

	@include xl {
		border-radius: 0 ;
	}
}

.arrow {
	@include xl {
		display: none !important;
	}
}

.moreActions .action {
	border-bottom: 1px solid #ececec;
	text-align: right;
	line-height: 30px;
	font-weight: 400;
	font-size: 14px;
}

.moreActions .action:hover {
	color: #3468ff;
	font-weight: 600;
}

.action span {
	margin-right: 5px;
}

.mobileTable {
	display: none;

	svg {
		transform: rotate(-90deg);
		path {
			stroke: #A8ABB7;
		}
	}

	th {
		text-align: right;
	}

	th, td {
		font-weight: bold;
	}

	tr:not(.tableExpand) td {
		vertical-align: inherit;
	}

	.open {
		display: table-row !important;
	
		span:first-child {
			color: #7AB7FF;
			margin-left: 10px;
		}
	
		span:last-child {
			font-weight: normal;
		}
	}
	
	.tableExpand {
		display: none;
		background-color: #EAF1FE;
	
		div {
			width: 100%;
			display: flex;
		}
	
		span {
			width: 35%;
    		margin: 5px 0 5px 0;
		}
	}

	@include xl {
		display: table;
	}
}

.mobileButton {
	position: relative;

	.controls {
		flex-direction: column;
		background: #FFFFFF;
		padding: 0;
		box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.09), 0px 1px 18px rgba(0, 0, 0, 0.08), 0px 3px 5px rgba(0, 0, 0, 0.16);
		height: auto;
		width: 165px !important;
		position: absolute;
		z-index: 3;
		top: 16px;
		left: 16px;
		display: none !important;

		div {
			padding: 0 10px 10px 0;
		}

		svg {
			margin: 5px auto 0 5px;
			width: 24px !important;
			height: 24px !important;

			path {
				stroke: black;
			}
		}

		.centralDiv {
			border-bottom: 1px solid #EAEAEA;
			border-top: 1px solid #EAEAEA;
			margin: 0 0 10px 0;
			padding: 10px 10px 10px 0;
		}

		span {
			font-weight: bold !important;
			width: 100%;
			color: #242530 !important;
		}
	}

	.openControls {
		display: flex !important;
	}
}