@import '../../../css/mixins.scss';

.card {
	margin: 30px 0 0 0;
	border-radius: 10px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin: 15px 15px 0 0;

	@include xl {
		margin: 0;
		margin-top: 16px;
		box-shadow: none;
		border-radius: 5px;
		padding: 16px;
		position: relative;
	}
}

.table {
	margin: 30px;
}

thead th {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #7ab7ff;
}

tbody td {
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: black;
	text-align: start;
}

.modal {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);

	@include xl {
		border-radius: 0;
		box-shadow: none;
		width: 100%;
		height: 100%;

		.modalHeader {
			display: none;
		}

		.modalBody {
			padding: 0;
			position: relative;

			.title {
				margin-top: 40px;
				margin-bottom: 38px;
				color: #242530;
				font-family: Rubik;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 19px;
			}

			.detailsForm {
				align-items: center;

				> div:not(:last-child) {
					margin: 10px 0;

					label {
						color: #7ab7ff;
						font-family: Rubik;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 19px;
					}

					input {
						border: 1px solid #a8abb7;
						box-sizing: border-box;
						border-radius: 5px;
						padding: 8px 10px;
						font-family: Rubik;
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 17px;
					}
				}
			}

			.modalFooter {
				position: absolute;
				bottom: 0;
				left: 0;
				background: #ffffff;
				box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.16);
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-direction: row-reverse;
				margin: 0;
				padding: 10px 16px;

				.button {
					background: #3468ff;
					border-radius: 4px;
					color: #fff;
					font-family: Rubik;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 17px;
					padding: 10px 30px;
					margin: 0;
					width: auto;
				}

				.closeModal {
					display: block !important;
					color: #a8abb7;
					font-family: Rubik;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 17px;
					border: none;
					padding: 10px 0;
					width: auto;
					margin: 0;
				}
			}
		}

		> div {
			width: 100%;
			height: 100%;
			max-width: inherit;
			max-height: inherit;
			margin: 0;
			padding: 0;

			> div {
				height: 100%;
				border-radius: 0;
			}
		}
	}
}

.modalFooter {
	display: flex;
	justify-content: center;
}

.cardTitle {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: right;
	color: #303030;

	@include xl {
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 16px;
		color: #7ab7ff;
		// margin-bottom: 20px;
		display: block;
		margin-bottom: 30px;
	}
}

.title {
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;

	@include xl {
		margin-bottom: 38px;
	}
}

.detailsForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
}

.detailsParagraph {
	min-height: 140px;
	font-size: 15px;
	margin: 0 20% 15px 0;
	text-align: right;
}

.detailsKey,
label {
	display: inline-block;
	width: 100px;
	font-weight: 500;
	font-size: 14px;
}

.button {
	width: max-content;

	@include xl {
		margin: 10px 15px;
		background-color: #3468ff;
		color: white;
	}
}

input[type='text'],
input[type='tel'] {
	height: 32px;
	width: 150px;
	border: 1px solid #a8abb7;
	border-radius: 5px;
}

.exitButton {
	margin-right: 10px;
	width: 10px;
}

.icon {
	width: 16px;
	height: 16px;
	align-self: start;

	@include xl {
		opacity: 0.5;
		margin: -5px;
		padding: 5px;
		width: 26px;
		height: 26px;
	}
}

@include xl {
	.mobileTable {
		margin-bottom: 0;
		display: flex;

		thead {
			width: 100px;
			border-bottom: none;

			tr {
				display: flex;
				flex-direction: column;

				th {
					padding: 10px 0;
					color: #101010;
					font-family: Rubik;
					font-style: normal;
					font-weight: 600;
					font-size: 16px;
					line-height: 19px;

					&:last-child {
						display: none;
					}
				}
			}
		}

		tbody {
			flex: 1;

			tr {
				display: flex;
				flex-direction: column;

				td {
					padding: 10px 0;
					color: #101010;
					font-family: Rubik;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 19px;

					&.tcontrols {
						padding: 0;
						margin: 0;
						position: absolute;
						left: 16px;
						top: 16px;
					}
				}
			}
		}
	}
}
