@import './css/mixins.scss';

/* .App {
	position: relative;
}
.main {
	position: absolute;
	background-color: #f8f9fa;
	width: 87%;
	min-height: 100vh;
	text-align: start;
	padding: 5% 30px 30px 30px;
	direction: rtl;
} */
.App {
	position: relative;
}
.main {
	position: absolute;
	top: 0;
	background-color: #f8f9fa;
	width: 87%;
	min-height: 100vh;
	text-align: start;
	padding: 5% 30px 30px 30px;
	direction: rtl;

	@include xl {
		width: 100%;
		position: inherit;
		padding: calc(3.5rem + 24px) 0;
		background-color: #EAEAEA;
	}
}

.container-title {
	display: none;
}

@include xl {
	.container-title {
		display: inline-block;
		font-family: Rubik;
		font-weight: 600;
		font-size: 24px;
		line-height: 28px;
		color: #242530;
		margin-bottom: 18px;
		width: 100%;
	}

	.container-tabs {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		width: 100vw;
		overflow: scroll;
		margin: 0 -15px;
		padding: 0 15px;
		margin-bottom: 24px;

		li {
			margin-left: 16px;

			&:last-child {
				padding-left: 16px;
			}

			a {
				font-family: Rubik;
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 21px;
				margin-left: 0px;
				white-space: nowrap;

				&.container-active-link {
					color: #3468FF;
					font-weight: 500;
				}
			}
		}
	}
}
