@import '../../../css/mixins.scss';

/* .wrapper {
	display: grid;
	grid-template-columns: auto auto auto auto auto;
	
	background-color: khaki;
} */
.wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-items: flex-start;
	align-items: flex-start;
}
/* .wrapper {
	display: grid;
	grid-template-columns: auto auto auto auto auto;
	grid-template-rows: auto auto auto auto auto;
	justify-items: center;
	align-items: start;
	grid-auto-flow: row;
} */

.hr {
	@include xl {
		margin: 16px -16px;
		background: #DBDCE8;
	}
}

.siteName {
	margin: 2% 2% 1% 2%;
	font-weight: 500;
	font-size: 14px;
	color: #7ab7ff;

	@include xl {
		margin: 0;
		font-family: Rubik;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
	}
}

@include xl {
	.siteWrapper {
		margin: 16px;
		padding: 16px;
		background: #fff;
		border-radius: 5px;

		.hr {
			display: none;
		}

		.siteName {
			font-size: 16px;
			line-height: 19px;
			border-bottom: 1px solid #DBDCE8;
			padding: 0 16px 16px 16px;
			margin: 0 -16px 8px -16px;
		}
	}
}