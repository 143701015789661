@import '../../../css/mixins.scss';

.modal {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06),
		2px 0px 10px rgba(0, 0, 0, 0.06);

	@include xl {
		border-radius: 0;
		box-shadow: none;
		width: 100%;
		height: 100%;

		.modalHeader {
			display: none;
		}

		.modalBody {
			padding: 0;
			position: relative;

			.title {
				margin-top: 40px;
				margin-bottom: 38px;
				color: #242530;
				font-family: Rubik;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 19px;
			}

			.detailsForm {
				align-items: center;

				> div:not(:last-child)  {
					margin: 10px 0;

					label {
						color: #7AB7FF;
						font-family: Rubik;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 19px;
					}

					input {
						border: 1px solid #A8ABB7;
						box-sizing: border-box;
						border-radius: 5px;
						padding: 8px 10px;
						font-family: Rubik;
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 17px;
					}
				}
			}

			.modalFooter {
				position: absolute;
				bottom: -10px;
				left: 0;
				background: #FFFFFF;
				box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.16);
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-direction: row-reverse;
				margin: 0;
				padding: 10px 16px;

				.button {
					background: #3468FF;
					border-radius: 4px;
					color: #fff;
					font-family: Rubik;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 17px;
					padding: 10px 30px;
					margin: 0;
					width: auto;
				}

				.closeModal {
					display: block !important;
					color: #A8ABB7;
					font-family: Rubik;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 17px;
					border: none;
					padding: 10px 0;
					width: auto;
					margin: 0;
				}
			}
		}

		> div {
			width: 100%;
			height: 100%;
			max-width: inherit;
			max-height: inherit;
			margin: 0;
			padding: 0;

			> div {
				height: 100%;
				border-radius: 0;
			}
		}
	}
}

.modalFooter {
	display: flex;
	justify-content: center;
}

.title {
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

.detailsForm {
	display: flex;
	flex-direction: column;
	min-height: max-content;
	font-size: 15px;
	text-align: right;
}

.detailsKey,
label {
	display: inline-block;
	width: 70px;
	font-weight: 500;
	font-size: 14px;
	margin-right: 10px;
}

.button {
	align-self: center;
	width: max-content;
}

input[type='text'],
input[type='tel'],
input[type='number'] {
	height: 32px;
	width: 150px;
	border: 1px solid #a8abb7;
	border-radius: 5px;
}

.exitButton {
	margin-right: 10px;
	width: 10px;
}
