@import './../../../css/mixins.scss';

h4 {
	display: none;
	font-weight: bold;

	@include xl {
		display: block;
	}
}

.wrapper {
}

.headerCrane ul {
	@include xl {
		padding: 0;
	}
}

.card {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin-top: 30px;
}
.title {
	font-weight: 500;
	text-align: center;
	font-size: 14px;
	margin-top: 10px;
}
.footerTitle {
	font-weight: 500;
	text-align: center;
	font-size: 14px;
	margin-bottom: 10px;
}

ul .link {
	color: gray;
	font-size: 15px;
	margin-left: 15px;
}

ul .link:hover {
	color: black;
}

.active {
	font-weight: 600;
	color: black;
	text-decoration-line: underline;
	text-decoration-style: solid;

	@include xl {
		color: #3468FF !important;
	}
}
