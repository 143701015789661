@import '../../../css/mixins.scss';

.main {
	display: flex;
	flex-direction: column;
	justify-items: center;

	@include xl {
		padding: 0 15px 0;
	}
}
.main h3 {
	display: none;
	font-weight: bold;
	
	@include xl {
		display: block;
	}
}

.filter {
	display: none;
	margin: 0 auto 0 0;
	font-weight: bold;
	width: max-content;

	@include xl {
		display: block;
	}
}
.filter svg {
	width: 24px;
	height: 24px;
}

.button {
	width: max-content;
	align-self: center;

	@include xl {
		width: 200px;
		height: 50px;
		border-radius: 5px;
		color: white;
		background-color: #3468FF;
	}
}

.loading {
	align-self: center;
}
