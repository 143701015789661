.wrapper {
	display: grid;
	background-color: #f6f6f9;
	height: 62rem;
}

.card {
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06),
		2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	height: 520px;
	width: 400px;
	justify-self: center;
	text-align: center;
	align-items: center;
	justify-items: center;
}

.logo {
	justify-self: end;
	padding: 24px;
}

.logo > img {
	width: 62px;
	height: 62px;
}

.title {
	margin-top: 15px;
	font-size: 24px;
	font-weight: 500;
}

.prevButton {
	align-self: start;
	color: rgba(78, 78, 78, 0.644);
	width: bold;
}

.subtitle {
	font-size: 15px;
	font-weight: 400;
	margin: 30px 0 30px 0;
	width: 280px;
}

.input {
	display: inline-flex;
	align-items: center;
}
.inputs,
.inputs2 {
	display: grid;
	grid-template-rows: 1fr 1fr 1fr;
	gap: 15px;
	justify-items: center;
	margin-bottom: 30px;
}

.inputs2 {
	margin-top: 40px;
}

.key {
	width: 50px;
	float: right;
	margin-left: 30px;
	font-size: 14px;
	font-weight: 500;
	color: #7ab7ff;
}

.key2 {
	width: 100px;
	float: right;
	margin-left: 15px;
	font-size: 14px;
	font-weight: 500;
	color: #7ab7ff;
}

.input .value {
	width: 167px;
	height: 32px;
	border: 1px solid #a8abb7;
	border-radius: 5px;
	text-align: right;
}

.resending {
	font-size: 12px;
}

.subtitle2 {
	font-size: 11px;
	width: 250px;
	text-align: right;
}

.button {
	width: max-content;
}

.policy {
	font-weight: 400;
	font-size: 9px;
	width: 200px;
}

.resending > span,
.policy > span {
	text-decoration: underline;
}

.resending > span:hover,
.policy > span:hover {
	color: #3468ff;
}

.confirmButton:hover {
	background: #0c32a1;
}

.eula {
	padding: 30px;
}
