@import '../../../css/mixins.scss';

.wrapper {
}

.card {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	margin-top: 30px;

	@include xl {
		box-shadow: none;
		margin-top: 16px;
	}

	&.box {
		padding: 16px 20px;

		.title {
			color: #7AB7FF;
			font-family: Rubik;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
		}
	}

	&.transparent {
		background: transparent;
	}
}

.title {
	font-weight: 500;
	text-align: center;
	font-size: 14px;
	margin-top: 10px;
}

.mainCard {
	position: relative;
	display: grid;
	grid-template-rows: 70% 30%;
	height: 200px;

	@include xl {
		height: auto;
		display: flex;
		flex-direction: column;
		margin-bottom: 27px;
	}
}

.badge {
	position: absolute;
	top: -13px;
	right: -15px;
	min-width: 25px;
	max-height: 25px;
	font-size: 11px;
	stroke: #ff0000;

	@include xl {
		display: none;
	}
}

.iconsCard {
	justify-content: center;
}

.mobileReverse {
	@include xl {
		flex-direction: column-reverse;
	}
}

.closeCraneControls {
	display: none;

	@include xl {
		position: absolute;
		left: 7px;
		top: 7px;
		display: block;
		height: 16px;
		width: 16px;

		path {
			stroke: #101010;
		}
	}
}

.top {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	justify-content: center;
	align-items: center;
	height: 145px;

	@include xl {
		display: flex;
		flex-direction: row;
		height: auto;
		padding: 20px 0;

		> div:not(.switchButtons) {
			width: 50%;
			padding: 20px;

			.craneP {
				font-family: Rubik;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 19px;
				color: #242530;
			}
		}

		> div.switchButtons {
			display: none;

			&.open {
				display: flex;
				flex-direction: column;
				position: absolute;
				top: 10px;
				left: 10px;
				background: #FFFFFF;
				box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.09), 0px 1px 18px rgba(0, 0, 0, 0.08), 0px 3px 5px rgba(0, 0, 0, 0.16);
				border-radius: 4px;
				z-index: 1;
				border: none;
				padding: 6px 0 6px 30px;

				.switchButton {
					margin: 6px 18px;
					display: flex;
					flex-direction: row-reverse;
					height: 24px;
					padding: 0;

					svg {
						margin-left: 5px;
					}
				}
			}
		}
	}

	@include md {
		flex-wrap: wrap;
	}

	@include sm {
		> div:not(.switchButtons) {
			width: 50%;
			padding: 20px 5px;
		}
	}
}

.top > div {
	text-align: center;
}

.moreControl {
	display: none;

	@include xl {
		display: block;
		position: absolute;
		width: 34px;
		height: 38px;
		left: 0;
		top: 0;
		padding: 10px 8px;
	}
}

.expandStatus {
	display: none;

	@include xl {
		position: absolute;
		bottom: -14px;
		left: calc(50% - 14px);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 28px;
		height: 28px;
		box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.16);
		background: #fff;
		border-radius: 14px;

		> svg {
			width: 20px;
			height: auto;
			margin-top: 1px;

			&.open {
				transform: rotateZ(180deg);
			}
			
			path {
				stroke: #242530;
			}
		}
	}
}

.bottom {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	align-items: center;
	justify-items: center;
	text-align: center;
	border-top: 1px solid lightgray;

	@include xl {
		display: none;

		&.open {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			padding: 24px 20px;
		}

		.detail {
			width: 50%;
			display: flex;
			padding: 8px 10px;

			.detailKey {
				color: #7AB7FF;
				font-family: Rubik;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 19px;
				margin-left: 12px;
			}

			.detailValue {
				text-align: right;
				color: #303030;
				font-family: Rubik;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 19px;
			}
		}
	}
}

.switchButtons {
	display: grid;
	grid-template-rows: repeat(3, 1fr);
	border-right: 1px solid lightgray;
}

.switchButton {
	display: flex;
	justify-content: center;
	align-items: center;
}

.detailKey {
	align-items: center;
	margin-left: 10px;
}
.detailValue {
	align-items: center;
}

li {
	display: inline;
}

.craneAlerts {
	min-height: 23rem;
	margin: 15px;
}
.alertKey {
	display: inline-block;
	width: 90%;
	font-weight: 500;
	font-size: 14px;
}

.craneActivity {
	min-height: 140px;
	margin: 15px;

	@include xl {
		margin: 0;
		min-height: 0px;
	}
}
.craneActivity > div {
	margin-top: 15px;

	@include xl {
		margin-top: 12px;

		&:nth-child(2) {
			margin-top: 24px;
		}

		span:last-child {
			font-family: Rubik;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 19px;
			color: #242530;
		}
	}
}

.activityKey,
.contactKey {
	display: inline-block;
	width: 130px;
	font-weight: 500;
	font-size: 14px;

	@include xl {
		color: #101010;
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
	}
}
.craneContact {
	min-height: 140px;
	margin: 15px;

	@include xl {
		margin: 0;
		min-height: 0px;
	}
}

.craneContact div {
	margin-top: 15px;

	@include xl {
		margin-top: 12px;

		&:nth-child(2) {
			margin-top: 24px;
		}

		span:last-child {
			font-family: Rubik;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 19px;
			color: #242530;
		}
	}
}

.footerActivity {
	margin-top: 20%;
	text-align: center;
	font-weight: 500;
	font-size: 14px;
	color: gray;

	svg {
		display: none;
	}

	@include xl {
		background: #E0ECFE;
		margin-bottom: -16px;
		margin-left: -20px;
		margin-right: -20px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		font-family: Rubik;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 17px;
		color: #242530;
		padding: 10px 0;
		margin-top: 24px !important;
		display: flex;
		justify-content: center;


		span {
			position: relative;
			margin-left: 30px;

			svg {
				display: block;
				position: absolute;
				left: -30px;
				transform: rotateZ(90deg);
				height: 20px;
				width: 20px;

				path {
					stroke: #242530;
				}
			}
		}
	}
}

.footerActivity:hover {
	color: rgba(0, 0, 0, 0.8);
}

.craneIcon {
	width: 30px;
	height: 30px;
}

.svgActiveStroke {
	stroke: #101010;
}
.svgActiveFill {
	fill: #101010;
}
.svgInactiveStroke {
	stroke: #dddddd;
}
.svgInactiveFill {
	fill: #dddddd;
}

.switchIcon {
	width: 19px;
	height: 19px;
	margin-bottom: 3px;
	padding: 1px;
}

.craneNotification {
	margin-top: 20px;
}

.operatorImg {
	height: 600px;
	width: 600px;
}

.exitButton {
	margin-right: 10px;
	width: 10px;
}

.imgLoading {
	display: inline-block;
	align-items: center;
	justify-content: center;
}

.loading {
	background-color: rgb(52, 140, 255);
	color: white;
	font-weight: 500;
	text-align: center;
	font-size: 20px;
	padding: 0 50px 0 20px;
}

.toggle.ios,
.toggle-on.ios,
.toggle-off.ios {
	border-radius: 20px;
}
.toggle.ios .toggle-handle {
	border-radius: 20px;
}
