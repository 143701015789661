@import '../../../css/mixins.scss';

.card {
	margin: 30px 0 0 0;
	border-radius: 10px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06),
		2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin: 15px 15px 0 0;

	@include xl {
		margin: 0;
		margin-top: 16px;
		box-shadow: none;
		border-radius: 5px;
		padding: 16px;
		position: relative;
	}
}

.moveCraneTable {
	margin-top: 30px;
}

.cardTitle {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: right;
	color: #303030;

	@include xl {
		color: #7AB7FF;
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
	}
}

.modalTitle {
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 30px;

	@include xl {
		height: 10%;
		margin: 35px 0 30px 0 !important;
		font-weight: bold;
		height: 5% !important;
	}
}

.moveCraneTable th {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #7ab7ff;
}

tbody td {
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: black;
}

.CranePositionModal {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06),
		2px 0px 10px rgba(0, 0, 0, 0.06);

	@include xl {
		border-radius: 0;
		z-index: 10000;
	
		div:first-child {
			border-radius: 0;
			margin: 0;
			height: 100%;
			max-width: none;
		}
	}
}

.modalHeader {
	@include xl {
		display: none;
	}
}

.detailsParagraph,
.detailsForm {
	min-height: 140px;
	font-size: 15px;
}

.detailsKey,
label {
	width: 80px;
	font-weight: 500;
	font-size: 14px;
	text-align: right;
}

.button {
	width: max-content;

	@include xl {
		margin: 10px 15px;
		background-color: #3468FF;
		color: white;
	}
}

.CranePositionModal input[type='text'],
.CranePositionModal input[type='tel'],
.CranePositionModal input[type='number'],
.CranePositionModal select {
	height: 32px;
	width: 150px;
	border: 1px solid #a8abb7;
	box-sizing: border-box;
	border-radius: 5px;
	margin-right: 10px;

	@include xl {
		width: 200px !important;
	}
}

.exitButton {
	margin: 5px 10px 0 5px;
	width: max-content;
	align-self: flex-end;
	font-weight: 500;
	font-size: 14px;
	color: #a8abb7;
}

.modalBody {
	display: grid;
	align-items: center;
	justify-items: center;

	@include xl {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0;
		height: 100%;
	}
}

.form {
	display: grid;
	gap: 15px;
	align-items: center;
	justify-items: center;

	@include xl {
		border-radius: 0;
		height: 90%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-items: center;

		div {
			height: auto !important;
			margin: 10px;
		}
	}
}

.form > div {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.craneIcon {
	width: 16px;
	height: 16px;
	float: left;
}

.modalFooter {
	p {
		display: none;
		color: #A8ABB7;
		margin: auto 15px;

		@include xl {
			display: inline;
		}
	}

	@include xl {
		display: flex;
		justify-content: space-between !important;
		box-shadow: 0px -5px 5px -5px #A8ABB7;
		width: 100%;
		margin: auto 0 0 0 !important;
	}
}

.mobileTitle {
	display: none;
}

.mobileTable {
	@include xl {
		margin-top: 30px;
		margin-bottom: 0;

		thead {
			display: none;
		}

		tbody {
			display: flex;
			flex-direction: column;

			tr {
				display: flex;
				flex-direction: column;

				td {
					display: flex;
					padding: 10px 0;

					span {
						&.mobileTitle {
							display: block;
							color: #101010;
							font-family: Rubik;
							font-style: normal;
							font-weight: 600;
							font-size: 16px;
							line-height: 19px;
							width: 120px;
						}

						&:not(.mobileTitle) {
							color: #101010;
							font-family: Rubik;
							font-style: normal;
							font-weight: 300;
							font-size: 16px;
							line-height: 19px;
						}
					}

					&:last-child {
						margin: 0;
						height: 0;
						padding: 0;
					}
				}
			}

			.craneIcon {
				position: absolute;
				top: 16px;
				left: 16px;
			}
		}
	}
}
