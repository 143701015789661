@import './../../../css/mixins.scss';

.modal {
	text-align: center;
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06),
		2px 0px 10px rgba(0, 0, 0, 0.06);
}

.modal div:first-child {
	@include xl {
		max-width: 100%;
		height: 100%;
		margin: 0;
	}
}

.modalHeader {
	@include xl {
		display: none;
	}
}

.modalBody {
	@include xl {
		padding: 3em 0 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.title {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;

	@include xl {
		margin-bottom: 20px !important;
		height: auto !important;
	}
}

.exitButton {
	margin-right: 10px;
	width: 10px;

	@include xl {
		margin: 10px 25px 0 0;
	}
}

.modalForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	flex-wrap: wrap;

	@include xl {
		height: 100%;
	}
}
.modalForm div {
	@include xl {
		margin-bottom: 15px;
	}
}
.modalForm div label {
	@include xl {
		width: 90px !important;
		font-weight: bold !important;
	}
}
.modalForm div input,
.modalForm div select {
	@include xl {
		width: 150px !important;
	}
}

.button {
	width: max-content;

	@include xl {
		margin: 15px;
		background-color: #3468FF;
		color: white;
	}
}

.detailsParagraph,
.form1,
.form2,
.form3 {
	min-height: 140px;
	font-size: 15px;
	margin: 0 15px 15px 0;
	text-align: right;
}

.form1,
.form2,
.form3 {
	@include xl {
		height: 100%;
		margin: 0;
	}
}

.form1 .detailsKey,
.form1 label,
.form3 .detailsKey,
.form3 label {
	display: inline-block;
	width: 110px;
	font-weight: 500;
	font-size: 14px;
}

.form1 input[type='text'],
.form1 input[type='tel'],
.form1 select,
.form3 input[type='text'],
.form3 input[type='tel'],
.form3 select {
	height: 32px;
	width: 110px;
	border: 1px solid #a8abb7;
	border-radius: 5px;
}

.form2 input[type='number'],
.form select {
	height: 32px;
	width: 40px;
	border: 1px solid #a8abb7;
	border-radius: 5px;
	margin: 0 5px 0 5px;
}

.form2 .detailsKey,
.form2 label {
	display: inline-block;
	width: 70px;
	font-weight: 500;
	font-size: 13px;
}

.form2 input[type='date'] {
	height: 32px;
	width: 140px;
	border: 1px solid #a8abb7;
	border-radius: 5px;
}

.form2 span {
	font-size: 12px;
}

.modalFooter {
	@include xl {
		display: flex;
		justify-content: space-between;
		box-shadow: 0px -5px 5px -5px #A8ABB7;
		width: 100%;
		margin: auto 0 0 0 !important;
	}
}

.closeMobile {
	display: none;
	padding: 0;

	@include xl {
		display: inline-block;
	}
}
.closeMobile p {
	margin: 1.3em 1em 0 0;
	color: #A8ABB7;
	font-weight: bold;
}
.closeMobile button {
	position: relative;
    top: -15px;
    right: 20px;
}
.closeMobile span {
	display: none;
}