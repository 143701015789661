@font-face {
	font-family: Rubik;
	src: url(./assets/fonts/Rubik-Regular.ttf);
}

body {
	position: relative;
	margin: 0;
	padding: 0;
	font-family: Rubik, sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f8f9fa;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
