@import '../../../css/mixins.scss';

.card {
	margin: 30px 0 0 0;
	border-radius: 10px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin: 15px 15px 0 0;

	@include xl {
		margin: 0;
		margin-top: 40px;
		box-shadow: none;
		padding: 0;
		border-radius: 0;
		background: 0;
	}
}

.table {
	margin: 30px;
}

.cardTitle {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: right;
	color: #303030;

	@include xl {
		font-family: Rubik;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
		color: #101010;
	}
}

.title {
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

thead th {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #7ab7ff;
}

tbody td {
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: black;
}

.theaders {
	display: none;
}

@include xl {
	.mobileTable {
		margin-bottom: 0;

		.thead {
			display: none;
		}

		.tbody {
			display: flex;
    		flex-direction: column;
		}

		.tcard {
			display: flex;
			flex-direction: column;
			background: white;
			width: 100%;
			margin-top: 16px;
			border-radius: 5px;

			> td {
				padding: 10px 16px;
				font-family: Rubik;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 19px;
				color: #303030;
			}

			.tname {
				margin-bottom: 14px;
				padding: 16px;
				border-bottom: 1px solid #DBDCE8;
				color: #7AB7FF;
				font-family: Rubik;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 19px;
				display: block;
			}

			.theaders {
				color: #101010;
				display: inline-block;
				font-family: Rubik;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 19px;
				width: 100px;
			}
		}
	}
}