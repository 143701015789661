@import '../../../css/mixins.scss';

.mainDiv {
	@include xl {
		margin-right: 0;
		width: 100%;
	}
}
.mainDiv h3 {
	display: none;
	font-weight: bold;

	@include xl {
		display: block;
	}
}

.col {
	@include xl {
		max-width: 100%;
    	flex: 100%;
	}
}

.card {
	border-radius: 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06), 2px 0px 10px rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 15px;
	margin-top: 30px;
}

.detailsParagraph,
.detailsForm,
.configParagraph {
	min-height: 140px;
	font-size: 14px;
	margin: 0 15px 15px 0;
}

.detailsParagraph div:first-child {
	margin-bottom: 20px;
}

.detailsKey,
label,
.subtitle {
	display: inline-block;
	width: 80px;
	font-weight: 500;
	font-size: 13px;
}

.details div{
	@include xl {
		margin-bottom: 5px !important;
	}
}

.detailsKey {
	@include xl {
		font-size: initial;
		font-weight: bold;
	}
}

.editButton {
	background: none;
	border: none;
	color: lightgray;
	float: left;
	margin-bottom: 10px;
	font-size: 14px;
}

.saveButton {
	position: relative;
	background: black;
	color: rgba(255, 255, 255, 0.788);
	font-weight: 500;
	float: left;
	border-radius: 5px;
	width: 100px;
	height: 30px;
	text-align: center;
	padding-top: 3px;
}

input {
	height: 32px;
	width: 160px;
	border: 1px solid #a8abb7;
	border-radius: 5px;
}

.title {
	font-weight: 500;

	@include xl {
		font-weight: bold;
		color: #7AB7FF;
	}
}

.subtitle {
	margin: 30px 0 10px 0;
}

.checkboxParagraph {
	font-size: 13px;
}

.checkbox {
	display: inline-block;
	margin-left: 5px;
	border: 6px solid black;
}
