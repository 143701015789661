@import './mixins.scss';

.green-border {
	border: 2px solid #2ed47a;

	@include xl {
		border: 1px solid #2ED47A;
	}
}

.red-border {
	border: 2px solid #ff5143;

	@include xl {
		border: 1px solid #FF5143;
		position: relative;

		&:after {
			content: '';
			background: url(../assets/icons/crane-control-warn.svg);
			position: absolute;
			right: -8px;
			top: -8px;
			width: 16px;
			height: 16px;
		}
	}
}

.gray-border {
	border: 2px solid #d1d1d1;

	@include xl {
		border: 1px solid #DBDCE8;
	}
}

.green-border,
.red-border,
.gray-border {
	transition: 1s;
}

.gray-bg {
	opacity: 0.2;
}
